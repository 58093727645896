import { FC } from 'react';

// import useHeaderPopin from './useHeaderPopin'

const HeaderPopinWrapper: FC = () => {
  // Disabled auth
  // const { headerPopinProps } = useHeaderPopin()

  // const HeaderPopinAuth = headerPopinProps.isOpen
  //   ? dynamic(() => import('../../components/molecules/HeaderPopinAuth'))
  //   : null

  return null;
};
export default HeaderPopinWrapper;