const colors = {
  pureBlack: '#000',
  pureWhite: '#FFF',
  arapawa: '#314C5C',
  bismark: '#546A77',
  greyChateau: '#98A5AD',
  pattensBlue: '#DCE1E4',
  creamCan: '#F2C75C',
  salomie: '#FFD978',
  bananaMania: '#FFE9AF',
  oldLace: '#FFF8E5',
  crusta: '#F8835B',
  atomicTangerine: '#F99871',
  melon: '#FBC1AD',
  chablis: '#F0E6D9',
  killarney: '#3E7A4B',
  bayLeaf: '#78C288',
  fringyFlower: '#ACDCB6',
  offGreen: '#DEF5E3',
  cornflowerBlue: '#5C8AFF',
  blue: '#56CCF2',
  matterhorn: '#4F4F4F',
  grey: '#828282',
  silver: '#BDBDBD',
  gainsboro: '#E0E0E0',
  snow: '#F9F9F9',
  linen: '#F7F2EC',
  floralWhite: '#FCFAF7',
  zircon: '#DCE1E4',
  aliceBlue: '#F8F9FB',
  error: '#BB2222',
  tangerineYellow: '#FFCF00',
  darkPink: '#BC0048',
  framboise: '#C82A64',
  facebook: '#1877F2',
  ruby: '#DC1E63',
  standardRed: '#EB5757',
  red: '#E25944',
  beige: '#EEE9D9',
  fireBrick: '#BC2F27',
  witchHaze: '#FFF37A',
  capriceDark: '#028ED0',
  capriceMedium: '#80C7E7',
  capriceUltraLight: '#F2F9FD',
}

export type Colors = typeof colors

export default colors
