import React, { FC } from 'react';
import { ActionButtonProps } from '../../components/atoms/ActionButton';
import { PictureProps } from '../../components/atoms/Picture';
import { MainLayoutProps } from '../../layouts/MainLayout';
import * as SC from './styled';
export type NotFoundTemplateProps = MainLayoutProps & {
  imageProps?: PictureProps;
  logoProps?: PictureProps;
  title?: string;
  subtitle?: string;
  buttonProps?: ActionButtonProps;
};
const NotFoundTemplate: FC<NotFoundTemplateProps> = ({
  imageProps,
  //logoProps,
  title,
  subtitle,
  buttonProps,
  ...layoutProps
}) => {
  return <SC.Layout {...layoutProps}>
      <SC.Container>
        {imageProps && <SC.BackgroundImage {...imageProps} />}
        <SC.Content>
          {title && <SC.Title dangerouslySetInnerHTML={{
          __html: title
        }} />}
          {subtitle && <SC.Subtitle dangerouslySetInnerHTML={{
          __html: subtitle
        }} />}
          {buttonProps && <SC.Button {...buttonProps} />}
        </SC.Content>
      </SC.Container>
    </SC.Layout>;
};
export default NotFoundTemplate;