import * as api from './api/redux'
import * as app from './app/redux'
import * as search from './search/redux'
import * as plans from './plans/redux'

export const actions = {
  app: app.actions,
  api: api.actions,
  search: search.actions,
  plans: plans.actions,
}

export const selectors = {
  app: app.selectors,
  api: api.selectors,
  search: search.selectors,
  plans: plans.selectors,
}

export const reducers = {
  app: app.reducer,
  api: api.reducer,
  search: search.reducer,
  plans: plans.reducer,
}
