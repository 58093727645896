import { getService } from '../../helpers/ReduxHelpers'
import type {
  QuerySearchEngineAutocompleteArgs,
  SearchEngineAutocompleteResponseItem,
  SearchEngineOrderBy,
  SearchEngineType,
} from '../../graphql/generated/api-graphql'
import { SearchEngineEnhancedResponse } from '../../graphql/Services/SearchEngine/queries/searchEngine'

export const searchEngineAutocompleteService = getService<
  'autocomplete',
  SearchEngineAutocompleteResponseItem[],
  QuerySearchEngineAutocompleteArgs
>('autocomplete')

export const soloSearchResultsService = getService<
  'soloSearchResults',
  SearchEngineEnhancedResponse,
  {
    q?: string
    type?: SearchEngineType
    first: number
    tags: (number | string)[]
    brandIds: (number | string)[]
    cheeseIds: (number | string)[]
    orderBy: SearchEngineOrderBy
  }
>('soloSearchResults')
