import { all, call } from 'redux-saga/effects'
import type { ApolloError } from '@apollo/client/errors'

import bugsnagClient from '../../helpers/BugsnagHelpers'
import client, { defaultConfig, persistedClient } from '../../graphql/client'

import type {
  ApiResponse,
  GraphqlQueryVariables,
  QueryType,
  ServiceMutation,
  ServiceQuery,
} from './types/state'

export type ApiTransformer = (data: any) => any

export default class ApiSagas {
  static *query(
    service: ServiceQuery,
    variables: GraphqlQueryVariables | null = null
  ) {
    const response: ApiResponse = yield ApiSagas.call(
      client.query,
      service,
      variables
    )
    return response
  }

  static *mutate(
    service: ServiceMutation,
    variables: GraphqlQueryVariables | null = null
  ) {
    const response: ApiResponse = yield ApiSagas.call(
      client.mutate,
      service,
      variables
    )
    return response
  }

  static *persistQuery(
    query: QueryType | any,
    variables: GraphqlQueryVariables | null = null
  ) {
    const response: ApiResponse = yield ApiSagas.call(
      persistedClient.query,
      query?.query ? query : { query },
      variables
    )
    return response
  }

  static *call(
    method:
      | typeof client.query
      | typeof client.mutate
      | typeof persistedClient.query,
    service: ServiceQuery | ServiceMutation,
    variables: GraphqlQueryVariables | null = null
  ) {
    let result: ApiResponse
    try {
      result = yield call(method as any, {
        ...defaultConfig,
        ...service,
        ...(variables && { variables }),
        context: {
          ...service?.context,
          headers: {
            ...service?.context?.headers,
          },
        },
      })
    } catch (e) {
      console.error(`ApiSagas - call errors :`, e, variables)

      if (bugsnagClient) {
        bugsnagClient.addMetadata('graphQL', {
          Variables: variables,
          Config: service,
        })
        bugsnagClient.notify(e as ApolloError)
      }

      return {
        errors: e,
      }
    }

    if (result.errors) {
      console.error(`ApiSagas - call result.errors `, result.errors)
    }

    const resultTransformed: ApiTransformer = yield call(
      ApiSagas.transform,
      result,
      service?.transformer
    )

    return resultTransformed
  }

  static *transform(result: ApiResponse, transformer: ApiTransformer) {
    if (!result.data || !transformer) {
      return result
    }

    const data: ApiTransformer = yield call(transformer, result.data as any)

    return { ...result, data } as ApiResponse
  }

  static *loop() {
    yield all([
      //
    ])
  }
}
