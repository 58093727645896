import debounce from 'lodash/debounce';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { HeaderSearchProps } from 'src/components/molecules/HeaderSearch';
import tracking from 'src/tracking';
import { SocialsProps } from 'src/components/atoms/Socials';
import { BurgerNavProps } from 'src/components/molecules/BurgerNav';
import { Icons } from '../../components/atoms/Icon';
import { HeaderProps } from '../../components/molecules/Header';
import { NavLink } from '../../components/molecules/MainNav';
import { ConfigurationFooterSocialLink, ConfigurationHeader, ConfigurationHeaderItem } from '../../graphql/generated/api-graphql';
import { actions, selectors } from '../../redux';
import Router, { routes } from '../../routes/Router';
import { wediaImagesTransform } from '../Common/wedia';
import { suggestionTransformer } from '../SearchEngine/searchSuggestion';
import { headerCardsTransformer } from './headerCardsTransformer';
import { headerColLinksTransformer } from './headerColLinksTransformer';
import HeaderPopinWrapper from './HeaderPopinWrapper';
export type UseHeaderProps = {
  data?: ConfigurationHeader;
  socials?: Array<ConfigurationFooterSocialLink>;
};
const useHeader = (data?: ConfigurationHeader, socials?: Array<ConfigurationFooterSocialLink>): HeaderProps => {
  const {
    t
  } = useTranslation();
  const [value, setValue] = useState('');
  const dispatch = useDispatch();
  // const setAuthOpen = useCallback(
  //   (value: boolean) => {
  //     dispatch(actions.auth.setAuthPopinOpen(value))
  //     dispatch(actions.auth.loginReset())
  //   },
  //   [dispatch]
  // )
  const {
    data: suggestions
  } = useSelector(selectors.search.autocomplete);
  const path = useRouter();
  const request = useMemo(() => debounce((q: string) => dispatch(actions.search.autocompleteRequest({
    q
  })), 200), [dispatch]);
  const navigate = (href: string) => {
    Router.push(href);
  };
  useEffect(() => {
    if (value?.length > 0) {
      request(value);
    } else {
      dispatch(actions.search.autocompleteReset());
    }
  }, [value, request, dispatch]);
  const highlightCards = useMemo(() => data?.items?.map(item => item.cards).flat() ?? [], [data?.items]);
  const onSearchInput = useCallback((e: any) => setValue(e.target.value), []);
  const onSearchSubmit = useCallback((value: string | {
    label: string;
  }) => {
    const isSuggestion = typeof value === 'string' ? false : 'label' in value && value?.label && true;
    const q = typeof value === 'string' ? value : 'label' in value && value.label ? value.label : '';
    dispatch(actions.search.setSearchDataLayer({
      search_bar_location: 'header',
      search_type: isSuggestion ? 'suggestion' : 'direct'
    }));
    Router.push(Router.getRouteUrl(routes.search, {
      q
    }));
  }, [dispatch]);
  const mainNav = useMemo(() => ({
    logoImageProps: {
      withHD: true,
      withLazyLoading: false,
      maxWidth: 110,
      width: 110,
      height: 110,
      alt: t('header_alt_logo'),
      images: [{
        src: '/static/assets/images/common/logo-110-min.png',
        size: 110
      }, {
        src: '/static/assets/images/common/logo-110-min.webp',
        size: 110
      }, {
        src: '/static/assets/images/common/logo-220-min.png',
        size: 220
      }, {
        src: '/static/assets/images/common/logo-220-min.webp',
        size: 220
      }]
    },
    title: t('header_alt_logo'),
    backLinkProps: path.pathname === '/' ? undefined : {
      href: Router.getRouteUrl(routes.home),
      label: t('header_homepage'),
      title: t('header_back_to_homepage')
    },
    navLinkProps: data?.items.map((item: ConfigurationHeaderItem): NavLink => {
      const left = item?.columns?.left;
      const right = item?.columns?.right;
      const colLinks = [...(left ? left?.columnLinks : []), ...(right ? right?.columnLinks : [])];
      const cardsData = [...(left ? left?.cards : []), ...(right ? right?.cards : [])];
      return {
        ...(item?.label && item?.href && {
          href: item?.href === '#' ? undefined : item?.href ?? undefined,
          onClick: () => tracking.header.menu(item?.label, 1),
          'aria-current': path.asPath === item.href ? 'true' : undefined
        }),
        label: item?.label ?? undefined,
        subNavProps: colLinks.length > 0 || cardsData.length > 0 ? {
          menus: colLinks.length > 0 ? headerColLinksTransformer(left, colLinks, path.asPath, navigate) : undefined,
          cards: cardsData.length > 0 ? headerCardsTransformer(t, right, cardsData) : undefined
        } : undefined
      };
    })
  }), [data?.items, path.asPath, path.pathname, t]);
  const search: HeaderSearchProps = useMemo(() => ({
    search: {
      name: 'search',
      texts: {
        button: t('searchPage_submit_label')
      },
      value,
      onChange: onSearchInput,
      onSubmit: onSearchSubmit,
      'aria-label': t('header_search_input'),
      suggestions: suggestions?.map(suggestionTransformer)
    }
  }), [t, value, onSearchInput, onSearchSubmit, suggestions]);
  const socialProps: SocialsProps | undefined = useMemo(() => socials && socials.length > 0 ? {
    linksProps: socials?.map(item => ({
      linkProps: {
        href: item.link.href,
        target: item.link.target,
        name: item.type,
        title: item.type,
        onClick: () => tracking.social(item.type, 'header')
      },
      iconProps: {
        icon: item.type === 'FACEBOOK' ? Icons.socialFacebook : item.type === 'INSTAGRAM' ? Icons.socialInstagram : item.type === 'TWITTER' ? Icons.socialX : item.type === 'PINTEREST' ? Icons.socialPinterest : Icons.cheese
      }
    }))
  } : undefined, [socials]);
  const burger: BurgerNavProps = useMemo(() => ({
    id: 'BurgerNav',
    texts: {
      open: t('header_burger_open'),
      close: t('header_burger_close'),
      back: t('header_burger_back')
    },
    ...(highlightCards && {
      highlightCards: highlightCards.map(card => ({
        title: card?.title,
        imageProps: {
          withHD: true,
          maxWidth: 250,
          width: 250,
          height: 250,
          alt: card?.title,
          images: card?.image ? wediaImagesTransform(card?.image) : undefined
        },
        link: {
          label: card?.wording,
          href: card?.href
        }
      }))
    }),
    navigation: data?.items.map((item: ConfigurationHeaderItem) => {
      const left = item.columns?.left;
      const right = item.columns?.right;
      const cardsData = [...(left ? left?.cards : []), ...(right ? right?.cards : [])];
      const colLinks = [...(left ? left?.columnLinks : []), ...(right ? right?.columnLinks : [])];
      return {
        link: item.label ? {
          href: item?.href === '#' ? undefined : item?.href ?? undefined,
          label: item.label,
          'aria-current': path.asPath === item.href ? 'true' : undefined
        } : undefined,
        menus: headerColLinksTransformer(left, colLinks, path.asPath, navigate),
        cards: headerCardsTransformer(t, right, cardsData)
      };
    })
  }), [data?.items, highlightCards, path.asPath, t]);
  return {
    mainNav,
    search: path.pathname !== routes.search.path ? search : undefined,
    skipNav: useMemo(() => ({
      linksProps: [{
        label: t('skip_to_content'),
        href: '#Content'
      }]
    }), [t]),
    // Disable Auth
    // iconsNav,
    socials: socialProps,
    burger,
    modals: <>
        <HeaderPopinWrapper />
      </>
  };
};
export default useHeader;