// import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
// import { useSelector } from 'react-redux'

import { Icons } from '../../components/atoms/Icon'
// import { RegisterPushValues } from '../../components/molecules/RegisterPush'
import { ConfigurationFooter } from '../../graphql/generated/api-graphql'
// import { selectors } from '../../redux'
import tracking from '../../tracking'
import { FooterProps } from '../../components/molecules/Footer'

export type UseFooterProps = {
  data?: ConfigurationFooter
}

const useFooter = (data?: ConfigurationFooter): FooterProps => {
  const { t } = useTranslation()

  return {
    imageProps: {
      alt: t('header_alt_logo'),
      withHD: true,
      withLazyLoading: true,
      maxWidth: 110,
      width: 110,
      height: 85,
      images: [
        {
          src: '/static/assets/images/common/logo-220-min.png',
          size: 220,
          type: 'image/png',
        },
        {
          src: '/static/assets/images/common/logo-220-min.webp',
          size: 220,
          type: 'image/webp',
        },
      ],
    },
    socialsProps: {
      label: t('footer_social'),
      linksProps: data?.socialLinks
        ? data?.socialLinks?.map((item) => ({
            linkProps: {
              href: item.link.href,
              target: item.link.target,
              name: item.type,
              title: item.type,
              onClick: () => tracking.social(item.type, 'footer'),
            },
            iconProps: {
              icon:
                item.type === 'FACEBOOK'
                  ? Icons.socialFacebook
                  : item.type === 'INSTAGRAM'
                  ? Icons.socialInstagram
                  : item.type === 'TWITTER'
                  ? Icons.socialX
                  : item.type === 'PINTEREST'
                  ? Icons.socialPinterest
                  : Icons.cheese,
              width: 40,
              height: 40,
            },
          }))
        : undefined,
    },
    legalLinks: data?.legalLinks.map((item) =>
      item.label
        ? {
            label: item.label,
            href: item.href,
            onClick: () => tracking.footer.menu(item.label ?? ''),
          }
        : {}
    ),
    customizeCookies: {
      onClick: () => {
        if (window && window.openAxeptioCookies) {
          window.openAxeptioCookies()
        }
      },
      label: t('footer_customize_cookies'),
      className: 'nav-bullet ot-sdk-show-settings tracked',
    },
    menuProps: {
      sections: data?.sectionsLinks
        ? data?.sectionsLinks.map((item) => ({
            link: item.link.label
              ? {
                  label: item.link.label,
                  href: item.link.href === '#' ? undefined : item.link.href,
                  ...(item.link.href !== '#' && {
                    onClick: () => tracking.footer.menu(item.link.label ?? ''),
                  }),
                }
              : {},
            links: item.links.map((item) =>
              item.label
                ? {
                    label: item.label,
                    href: item.href === '#' ? undefined : item.href,
                    ...(item.href !== '#' && {
                      onClick: () => tracking.footer.menu(item.label ?? ''),
                    }),
                  }
                : {}
            ),
          }))
        : [],
    },
  }
}

export default useFooter
