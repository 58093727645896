import Maybe from 'graphql/tsutils/Maybe'

import { NavCards } from '../../components/molecules/SubNav'
import {
  ConfigurationHeaderItem2ColumnsColumn,
  ConfigurationHeaderItem2ColumnsColumnCard,
} from '../../graphql/generated/api-graphql'
import { wediaImagesTransform } from '../Common/wedia'

export const headerCardsTransformer = (
  t: any,
  right: Maybe<ConfigurationHeaderItem2ColumnsColumn>,
  cardsData: Array<ConfigurationHeaderItem2ColumnsColumnCard>
): NavCards => {
  // console.log('cardsData', cardsData)
  return {
    content:
      cardsData.length > 0
        ? cardsData.map((item: any) => ({
            title: item?.title ?? undefined,
            subtitle: item?.subTitle ?? undefined,
            imageProps: {
              withHD: true,
              maxWidth: 250,
              width: 250,
              height: 250,
              alt: item?.title,
              images: item?.image
                ? wediaImagesTransform(item?.image)
                : undefined,
            },
            link: item?.href
              ? {
                  href: item.href,
                  label: item.wording ?? t('header_button_card'),
                }
              : undefined,
          }))
        : undefined,
    bottomLink: right?.bottomText
      ? {
          ...(right?.bottomTextUrl && { href: right.bottomTextUrl }),
          label: right.bottomText,
        }
      : undefined,
  }
}
