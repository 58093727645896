import { Icons } from '../../components/atoms/Icon'
import { SoloActionBlockTextSize } from '../../components/molecules/SoloActionBlock/types'
import { SoloHeroShapeStyle } from '../../components/molecules/SoloHero/types'
import brandColors from '../brandColors/britishHeritage'
import colors from '../colors'

import { BrandSchemeType } from './types'

const brandScheme: BrandSchemeType = {
  DarkToneColor: brandColors.DarkToneColor,
  MediumToneColor: brandColors.MediumToneColor,
  LightToneColor: brandColors.LightToneColor,
  UltraLightToneColor: brandColors.UltraLightToneColor,
  HighLightToneColor: brandColors.HighLightToneColor,
  // general
  generalHeaderFill: colors.pureWhite,
  generalBodyFill: brandColors.UltraLightToneColor,
  // hero
  heroShape: Icons.soloHeroRound,
  heroShapeStyle: SoloHeroShapeStyle.Default,
  // nav
  navButtonColor: brandColors.MediumToneColor,
  navButtonColorActive: colors.pureWhite,
  navButtonBorder: brandColors.MediumToneColor,
  navButtonBorderActive: brandColors.MediumToneColor,
  navButtonBackground: colors.pureWhite,
  navButtonBackgroundActive: brandColors.MediumToneColor,
  // anchors (brand)
  anchorsFill: brandColors.UltraLightToneColor,
  anchorsFillHover: brandColors.LightToneColor,
  anchorsFillActive: brandColors.LightToneColor,
  anchorsColor: brandColors.DarkToneColor,
  anchorsColorHover: brandColors.DarkToneColor,
  anchorsColorActive: brandColors.DarkToneColor,
  anchorsFixedFill: colors.pureWhite,
  anchorsFixedFillHover: brandColors.LightToneColor,
  anchorsFixedFillActive: brandColors.DarkToneColor,
  anchorsFixedColor: brandColors.DarkToneColor,
  anchorsFixedColorHover: brandColors.DarkToneColor,
  anchorsFixedColorActive: colors.pureWhite,
  anchorsTitleColor: brandColors.DarkToneColor,
  anchorsScrollbarColor: brandColors.LightToneColor,
  anchorsScrollbarBGColor: brandColors.UltraLightToneColor,
  // brand intro
  brandIntroTitleColor: brandColors.DarkToneColor,
  brandIntroSubtitleColor: brandColors.DarkToneColor,
  // contact us
  contactUsTitleColor: brandColors.DarkToneColor,
  contactUsSubtitleColor: brandColors.MediumToneColor,
  // socials
  socialsTitleColor: brandColors.DarkToneColor,
  socialsFill: brandColors.HighLightToneColor,
  socialsFillActive: brandColors.HighLightToneColor,
  socialsColor: brandColors.UltraLightToneColor,
  // news - cards
  newsCardsTitle: brandColors.DarkToneColor,
  // news - push image
  newsPushImageTitle: brandColors.DarkToneColor,
  // news - video player
  newsVideoTitle: brandColors.DarkToneColor,
  // news - video list
  newsVideoListFill: colors.pureWhite,
  newsVideoListTitle: brandColors.DarkToneColor,
  newsVideoListSubtitle: brandColors.MediumToneColor,
  newsVideoListVideoTitle: brandColors.MediumToneColor,
  newsVideoListThumbnailFill: '#F5EFED',
  newsVideoListThumbnailFillHover: brandColors.MediumToneColor,
  newsVideoListThumbnailFillActive: brandColors.MediumToneColor,
  newsVideoListThumbnailTextColor: colors.arapawa,
  newsVideoListThumbnailTextColorActive: colors.pureWhite,
  // content (common to history and engagement at least)
  contentSide: brandColors.MediumToneColor,
  contentDateTitle: brandColors.MediumToneColor,
  contentSectionTitle: brandColors.MediumToneColor,
  contentBlockTitle: brandColors.DarkToneColor,
  contentInsideTitle: brandColors.MediumToneColor,
  contentInsideSubTitle: brandColors.DarkToneColor,
  contentQuote: brandColors.MediumToneColor,
  // content - action block
  contentActionTitle: brandColors.DarkToneColor,
  contentActionText: colors.matterhorn,
  contentActionTextSize: SoloActionBlockTextSize.Small,
  // content anchors (...)
  contentAnchorsColor: brandColors.MediumToneColor,
  contentAnchorsColorActive: colors.pureWhite,
  contentAnchorsBorder: brandColors.MediumToneColor,
  contentAnchorsBorderActive: brandColors.MediumToneColor,
  contentAnchorsBackground: colors.pureWhite,
  contentAnchorsBackgroundActive: brandColors.MediumToneColor,
  // history
  historyTitle: brandColors.DarkToneColor,
  // history - anchors
  historyAnchorsFill: colors.pureWhite,
  historyAnchorsFillHover: brandColors.DarkToneColor,
  historyAnchorsFillActive: brandColors.DarkToneColor,
  historyAnchorsColor: colors.bismark,
  historyAnchorsColorHover: colors.pureWhite,
  historyAnchorsColorActive: colors.pureWhite,
  // contact
  contactTitle: brandColors.DarkToneColor,
  // coupons
  couponsTitle: brandColors.DarkToneColor,
}

export default brandScheme
