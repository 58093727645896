import { BrandColorType } from './types'

const brandColors: BrandColorType = {
  DarkToneColor: '#1C1A16',
  MediumToneColor: '#056646',
  LightToneColor: '#FBF0CB',
  UltraLightToneColor: '#FFFAEC',
  HighLightToneColor: '#ECAA08',
}

export default brandColors
