import { BrandColorType } from './types'

const brandColors: BrandColorType = {
  DarkToneColor: '#153D8A',
  MediumToneColor: '#5BC5F2',
  LightToneColor: '#D6B160',
  UltraLightToneColor: '#EAF3FA',
  HighLightToneColor: '#E30613',
}

export default brandColors
