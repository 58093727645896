import { all, fork } from 'redux-saga/effects'

// App
import AppSagas from './app/sagas'
import ApiSagas from './api/sagas'
import SearchSagas from './search/sagas'
import PlansSagas from './plans/sagas'

function* loop() {
  yield all([
    ApiSagas.loop(),
    AppSagas.loop(),
    SearchSagas.loop(),
    PlansSagas.loop(),
  ])
}

export default function* rootSaga() {
  yield fork(loop)
}
